import React from 'react'
import { Pricing } from '../Components/Pricing';

const Landing = () => {
    return (
        <div>
            <div class="relative bg-white">
                <div class="max-w-screen mx-auto p-2">
                    <div class="relative z-10 pb-8 bg-white sm:pb-16 md:pb-20 lg:max-w-2xl lg:w-full lg:pb-28 xl:pb-32">
                        <svg class="hidden lg:block absolute right-0 inset-y-0 h-full w-48 text-white transform translate-x-1/2"
                            fill="currentColor" viewBox="0 0 100 100" preserveAspectRatio="none" aria-hidden="true">
                            <polygon points="50,0 100,0 50,100 0,100" />
                        </svg>

                        <div class="relative pt-6 px-4 sm:px-6 lg:px-8">
                            <nav class="relative flex items-center justify-between sm:h-10 lg:justify-start"
                                aria-label="Global">
                                <div class="flex items-center flex-grow flex-shrink-0 lg:flex-grow-0">
                                    <div class="flex items-center justify-between w-full md:w-auto">
                                        <a href="/">
                                            <span class="sr-only">Igloo</span>
                                            <img class="h-24 w-auto sm:h-15" src="/assets/Iglogo.png" alt="Igloo Logo" />
                                        </a>
                                    </div>
                                </div>
                                <div class="block ml-10 pr-4 space-x-8">

                                    <a href="#features"
                                        class="lg:text-lg sm:text-3xl text-gray-500 hover:text-gray-900">Features</a>
                                    <a href="#pricing"
                                        class="lg:text-lg sm:text-3xl text-gray-500 hover:text-gray-900">Pricing</a>
                                    <a href="/faq"
                                        class="lg:text-lg sm:text-3xl text-gray-500 hover:text-gray-900">FAQ</a>
                                    {/* <a href="https://blog.igloosocial.com"
                                        class="lg:text-lg sm:text-3xl text-gray-500 hover:text-gray-900">Blog</a> */}

                                </div>
                            </nav>
                        </div>

                        <main class="mt-10 mx-auto max-w-7xl px-4 sm:mt-12 sm:px-6 md:mt-16 lg:mt-20 lg:px-8 xl:mt-28">
                            <div class="sm:text-center lg:text-left">
                                <h1 class="text-4xl tracking-tight font-extrabold text-gray-900 sm:text-5xl md:text-6xl">
                                    <span class="block xl:inline">Introducing <span class="text-blue-500">Igloo</span> -
                                The</span>
                                    <span class="block text-red-600 xl:inline"> Ad-Free, Private, Modern </span>
                                    <span class="block xl:inline">Social Media Platform</span>
                                </h1>
                                <h1 class="text-xl tracking-tight font-extrabold text-gray-900 sm:text-2xl md:text-3xl mt-4">
                                    Developed By <span class="block text-red-600 xl:inline"> Ashwin Alaparthi</span>
                                </h1>
                                <p
                                    class="mt-3 text-base text-gray-500 sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-xl lg:mx-0">
                                    Igloo lets you connect better with friends and family, and provides a fresh new social media experience without selling your data. Get maximum
                                    customizability over who sees your posts, catch up with friends in Igloo Spaces, and so much
                            more! <span class="text-black">Don't compromise your privacy for Social Media</span>, just
                            switch to Igloo!
                        </p>
                                <div class="mt-5 sm:mt-8 flex flex-col md:flex-row justify-center lg:justify-start items-center">
                                    <a href="https://play.google.com/store/apps/details?id=com.igloosocial.app">
                                        <img class="h-15 w-48 m-2" src="/assets/google-play-badge.png" alt="Play Store Buton" />
                                    </a>
                                    <a href="https://apps.apple.com/us/app/igloo-private-social-media/id1560450829">
                                        <img class="h-15 w-48 m-2" src="/assets/App Store.svg" alt="App Store Buton" />
                                    </a>
                                </div>
                                <div class="mt-5 sm:mt-8 sm:flex sm:justify-center lg:justify-start p-10">
                                </div>
                            </div>
                        </main>
                    </div>
                </div>
                <div class="lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">
                    <img class="w-full object-cover sm:h-full md:h-full lg:w-full lg:h-full md:p-32 sm:p-8" src="/assets/landing.png"
                        alt="A mockup of the app showing the feed and Igloo Spaces" />
                </div>
            </div>
            <div id="features">
                <div class="grid lg:grid-cols-2 md:grid-cols-1">
                    <div class="p-10 flex flex-col justify-center">
                        <h1 class="text-3xl tracking-tight font-extrabold text-gray-900 sm:text-5xl md:text-3xl">
                            Enjoy an Ad-Free Feed to focus on what matters
                </h1>
                        <br />
                        <h2 class="text-2xl tracking-tight font-bold text-gray-500 sm:text-3xl md:text-2xl">
                            Have an uninterrupted, <span class="text-red-500">Ad-Free, chronological feed</span> so you never
                    miss anything due to clunky
                    algorithms that decide what you see. <br /><br />Share better with <span class="text-red-500">Markdown
                        Support</span> and directly share <span class="text-red-500">Songs, Movies, TV Shows</span> and
                    more!
                </h2>
                    </div>
                    <img class="w-full object-cover sm:h-12 md:h-auto lg:h-auto md:p-48 p-8" src="/assets/Homefeed.png"
                        alt="A mockup of the app showing the feed and Igloo Spaces" />

                    <div class="p-10 flex flex-col justify-center">
                        <h1 class="text-3xl tracking-tight font-extrabold text-gray-900 sm:text-5xl md:text-3xl">
                            Create Spaces to connect with your friends or talk to the world
                </h1>
                        <br />
                        <h2 class="text-2xl tracking-tight font-bold text-gray-500 sm:text-3xl md:text-2xl">
                            Igloo <span class="text-red-500"> Discussion Spaces</span> provide a fun and casual voice room to
                    talk to your friends and catch up
                    with them. Make plans with your friend, or open up your Space to the public and <span
                                class="text-red-500">create your own talk
                        show!</span>
                            <br /> Watch Together Spaces provide a way to <span class="text-red-500">watch your favourite videos
                        together</span> with your favourite people!
                </h2>
                    </div>
                    <img class="w-full object-cover sm:h-full md:h-full lg:w-full lg:h-full p-8 md:p-32" src="/assets/Spaces.png"
                        alt="A mockup of the app showing Igloo Spaces" />

                    <div class="p-10 flex flex-col justify-center">
                        <h1 class="text-3xl tracking-tight font-extrabold text-gray-900 sm:text-5xl md:text-3xl">
                            Share More, Share Better with Circles
                    </h1>
                        <br />
                        <h2 class="text-2xl tracking-tight font-bold text-gray-500 sm:text-3xl md:text-2xl">
                            <span class="text-red-500">Create unlimited circles</span> in Igloo and add any of your followers to a
                        circle. When you <span class="text-red-500">share a post
                            with
                            one or more circles</span>, only
                        those group of people can see and interact with it. You don't need a seperate private profile or
                        overshare with
                        everyone, have <span class="text-red-500">complete control over your posts!</span>
                        </h2>
                    </div>
                    <img class="w-full object-cover sm:h-full md:h-full lg:w-full lg:h-full p-8 md:p-32" src="/assets/Circles.png"
                        alt="A mockup of the app showing Igloo Spaces" />
                </div>
            </div>
            <h1 class="text-3xl font-extrabold text-gray-400 w-full  text-center">
                Other Features
    </h1>
            <div class="lg:flex">
                <div class="p-10 flex flex-col justify-center">
                    <h1 class="text-3xl tracking-tight font-extrabold text-gray-900 sm:text-5xl md:text-3xl">
                        Create Pages to share with the world
            </h1>
                    <br />
                    <h2 class="text-2xl tracking-tight font-bold text-gray-500 sm:text-3xl md:text-2xl">
                        While profiles are private by default, Igloo Pages provide <span class="text-red-500">a public platform
                    where you can share with the world</span>
                your art,
                start a blog,
                or anything you want to share with anyone. Any user on Igloo can view pages and interact with them!
            </h2>
                </div>
            </div>
            <div class="lg:flex">
                <div class="p-10 flex flex-col justify-center">
                    <h1 class="text-3xl tracking-tight font-extrabold text-gray-900 sm:text-5xl md:text-3xl">
                        Maximum Privacy
            </h1>
                    <br />
                    <h2 class="text-2xl tracking-tight font-bold text-gray-500 sm:text-3xl md:text-2xl">
                        Igloo is built from the ground up with Privacy in mind. The platform has <span class="text-red-500">0
                    Trackers</span> and does not sell your data to any 3rd party.
            </h2>
                </div>
                <div class="p-10 flex flex-col justify-center">
                    <h1 class="text-3xl tracking-tight font-extrabold text-gray-900 sm:text-5xl md:text-3xl">
                        Beautiful Designs
            </h1>
                    <br />
                    <h2 class="text-2xl tracking-tight font-bold text-gray-500 sm:text-3xl md:text-2xl">
                        A Modern, Clean and Simple Design to help you share better with everyone. Enjoy a <span
                            class="text-red-500">Beautiful UI</span> with Themes for your best experience.
            </h2>
                </div>
            </div>
            <br />
            <h1 class="text-3xl tracking-tight font-normal text-gray-900 text-center sm:text-5xl md:text-3xl">
                And So much more!
    </h1>
            <div id="pricing">
                <Pricing />
            </div>
            <div class="rounded-md flex justify-center mt-10 mb-10 w-full">
                <a href="/"
                    class=" px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-red-600 hover:bg-red-700 md:py-4 md:text-lg md:px-10">
                    Get started for free
        </a>
            </div>


            <footer class="bg-gray-50 text-gray-600 px-6 lg:px-8 py-6 md:py-12">
                <div class="max-w-screen-xl mx-auto grid md:grid-cols-2 lg:grid-cols-5 gap-6 lg:gap-x-8">
                    <div>
                        <h5 class="text-xl font-semibold text-gray-700">Igloo</h5>
                        <nav class="mt-4">
                            <ul class="space-y-2">
                                <li>
                                    <a href="/" class="font-normal text-base hover:text-gray-400">Go back to the top</a>
                                </li>
                                <li>
                                    <a href="#features" class="font-normal text-base hover:text-gray-400">Features</a>
                                </li>
                                <li>
                                    <a href="#getitnow" class="font-normal text-red-500 text-base hover:text-gray-400">Get it
                                now</a>
                                </li>
                            </ul>
                        </nav>
                    </div>
                    <div>
                        <h5 class="text-xl font-semibold text-gray-700">About us</h5>
                        <nav class="mt-4">
                            <ul class="space-y-2">
                                <li>
                                    <a href="/" class="font-normal text-base hover:text-gray-400">Company</a>
                                </li>
                                <li>
                                    <a href="/" class="font-normal text-base hover:text-gray-400">Media Kit</a>
                                </li>
                            </ul>
                        </nav>
                    </div>
                    <div>
                        <h5 class="text-xl font-semibold text-gray-700">Legal</h5>
                        <nav class="mt-4">
                            <ul class="space-y-2">
                                <li>
                                    <a href="/termsandconditions" class="font-normal text-base hover:text-gray-400">Terms and conditions</a>
                                </li>
                                <li>
                                    <a href="/privacypolicy" class="font-normal text-base hover:text-gray-400">Privacy and Cookie
                                Statement</a>
                                </li>
                            </ul>
                        </nav>
                    </div>

                    <div>
                        <h5 class="text-xl font-semibold text-gray-700">Contact</h5>
                        <div class="space-y-4 md:space-y-6 mt-4">
                            <div class="flex items-start space-x-4">
                                <div>
                                    <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6 mt-1 text-gray-500" fill="none"
                                        viewBox="0 0 24 24" stroke="currentColor">
                                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                            d="M8 12h.01M12 12h.01M16 12h.01M21 12c0 4.418-4.03 8-9 8a9.863 9.863 0 01-4.255-.949L3 20l1.395-3.72C3.512 15.042 3 13.574 3 12c0-4.418 4.03-8 9-8s9 3.582 9 8z" />
                                    </svg>
                                </div>
                                <div class="flex-1">
                                    <a class="not-italic" href="https://discord.gg/aBjSnvgs7a">
                                        Join our Discord Server
                            </a>
                                </div>
                            </div>
                            <div class="flex items-start space-x-4">
                                <div>
                                    <svg class="w-6 h-6 mt-1 text-gray-500" fill="none" stroke="currentColor"
                                        viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                            d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z">
                                        </path>
                                    </svg>
                                </div>
                                <div class="flex-1">
                                    <a href="mailto:support@igloosocial.com"
                                        class="hover:text-gray-500">support@igloosocial.com</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    class="max-w-screen-xl mx-auto flex flex-col md:flex-row justify-between items-center space-y-4 mt-16 lg:mt-20">
                    <img class="h-12" src="/assets/Iglogo.png" alt="Igloo logo" />
                    <div class="text-sm">
                        &copy;2021 Igloo. All rights reserved.
            </div>
                </div>
            </footer>
        </div>
    )
}

export default Landing;