const TwitterVerification = () => {
    return (
        <div>
            <p className="mt-12 text-2xl text-gray-800 text-center">Proof of ownership for Twitter Verification</p>
            <p className="mt-8 text-lg text-center mx-12">
                To whomsoever this may concern, <br /><br />
                Request you to take this page as proof of verification for Igloo - The Private Social Media and grant ownership of the Twitter handle @igloosocial which is now suspended and out of use.<br /><br />
                Igloo authorises Ashwin Alaparthi as founder of Igloo to request for the handle @igloosocial on Twitter. Currently we use the handle @igloo_social.
                ID Proof of Ashwin Alaparthi has been included in the PDF submitted in the request form. For further questions, please revert back to ashwin@igloosocial.com
            </p>
        </div>
    );
}

export default TwitterVerification;