export const Pricing = () => {
    return (
        <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
            <div className="max-w-xl mb-10 md:mx-auto sm:text-center lg:max-w-2xl md:mb-12">
                <h2 className="max-w-lg mb-6 font-sans text-3xl font-bold leading-none tracking-tight text-gray-900 sm:text-4xl md:mx-auto">
                    <span className="relative inline-block">
                        <svg
                            viewBox="0 0 52 24"
                            fill="currentColor"
                            className="absolute top-0 left-0 z-0 hidden w-32 -mt-8 -ml-20 text-blue-gray-100 lg:w-32 lg:-ml-28 lg:-mt-10 sm:block"
                        >
                            <defs>
                                <pattern
                                    id="ace59d72-08d5-4850-b9e4-d9d0b86c0525"
                                    x="0"
                                    y="0"
                                    width=".135"
                                    height=".30"
                                >
                                    <circle cx="1" cy="1" r=".7" />
                                </pattern>
                            </defs>
                            <rect
                                fill="url(#ace59d72-08d5-4850-b9e4-d9d0b86c0525)"
                                width="52"
                                height="24"
                            />
                        </svg>
                        <span className="relative">Start for Free. Pay for More.</span>
                    </span>{' '}
                </h2>
                <p className="text-base text-gray-700 md:text-lg">
                    Privacy and all the features at the cost of a cup of coffee
        </p>
            </div>
            <div className="grid max-w-md gap-10 row-gap-5 sm:row-gap-10 lg:max-w-screen-md lg:grid-cols-2 sm:mx-auto">
                <div className="flex flex-col justify-between p-5 bg-white border rounded shadow-sm">
                    <div className="mb-6">
                        <div className="flex items-center justify-between pb-6 mb-6 border-b">
                            <div>
                                <p className="text-sm font-bold tracking-wider uppercase">
                                    Get started for
                </p>
                                <p className="text-5xl font-extrabold">Free</p>
                            </div>
                            <div className="flex items-center justify-center w-24 h-24 rounded-full bg-blue-gray-50">
                                <svg
                                    className="w-10 h-10 text-gray-600"
                                    viewBox="0 0 24 24"
                                    strokeLinecap="round"
                                    strokeLidth="2"
                                >
                                    <path
                                        d="M12,7L12,7 c-1.657,0-3-1.343-3-3v0c0-1.657,1.343-3,3-3h0c1.657,0,3,1.343,3,3v0C15,5.657,13.657,7,12,7z"
                                        fill="none"
                                        stroke="currentColor"
                                    />
                                    <path
                                        d="M15,23H9v-5H7v-6 c0-1.105,0.895-2,2-2h6c1.105,0,2,0.895,2,2v6h-2V23z"
                                        fill="none"
                                        stroke="currentColor"
                                    />
                                </svg>
                            </div>
                        </div>
                        <div>
                            <p className="mb-2 font-bold tracking-wide">Features</p>
                            <ul className="space-y-2">
                                <li className="flex items-center">
                                    <div className="mr-2">
                                        <svg
                                            className="w-4 h-4 text-deep-purple-accent-400"
                                            viewBox="0 0 24 24"
                                            strokeLinecap="round"
                                            strokeLidth="2"
                                        >
                                            <polyline
                                                fill="none"
                                                stroke="currentColor"
                                                points="6,12 10,16 18,8"
                                            />
                                            <circle
                                                cx="12"
                                                cy="12"
                                                fill="none"
                                                r="11"
                                                stroke="currentColor"
                                            />
                                        </svg>
                                    </div>
                                    <p className="font-medium text-gray-800">
                                        Unlimited Text, Image, Song and TV posts
                  </p>
                                </li>
                                <li className="flex items-center">
                                    <div className="mr-2">
                                        <svg
                                            className="w-4 h-4 text-deep-purple-accent-400"
                                            viewBox="0 0 24 24"
                                            strokeLinecap="round"
                                            strokeLidth="2"
                                        >
                                            <polyline
                                                fill="none"
                                                stroke="currentColor"
                                                points="6,12 10,16 18,8"
                                            />
                                            <circle
                                                cx="12"
                                                cy="12"
                                                fill="none"
                                                r="11"
                                                stroke="currentColor"
                                            />
                                        </svg>
                                    </div>
                                    <p className="font-medium text-gray-800">1 Free Discussion Space per day</p>
                                </li>
                                <li className="flex items-center">
                                    <div className="mr-2">
                                        <svg
                                            className="w-4 h-4 text-deep-purple-accent-400"
                                            viewBox="0 0 24 24"
                                            strokeLinecap="round"
                                            strokeLidth="2"
                                        >
                                            <polyline
                                                fill="none"
                                                stroke="currentColor"
                                                points="6,12 10,16 18,8"
                                            />
                                            <circle
                                                cx="12"
                                                cy="12"
                                                fill="none"
                                                r="11"
                                                stroke="currentColor"
                                            />
                                        </svg>
                                    </div>
                                    <p className="font-medium text-gray-800">5 Circles with unlimited members</p>
                                </li>
                                <li className="flex items-center">
                                    <div className="mr-2">
                                        <svg
                                            className="w-4 h-4 text-deep-purple-accent-400"
                                            viewBox="0 0 24 24"
                                            strokeLinecap="round"
                                            strokeLidth="2"
                                        >
                                            <polyline
                                                fill="none"
                                                stroke="currentColor"
                                                points="6,12 10,16 18,8"
                                            />
                                            <circle
                                                cx="12"
                                                cy="12"
                                                fill="none"
                                                r="11"
                                                stroke="currentColor"
                                            />
                                        </svg>
                                    </div>
                                    <p className="font-medium text-gray-800">1 Free Page</p>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div>
                        <a
                            href="/#"
                            className="inline-flex items-center justify-center w-full h-12 px-6 mb-4 font-medium tracking-wide text-white transition duration-200 bg-gray-800 rounded shadow-md hover:bg-gray-900 focus:shadow-outline focus:outline-none"
                        >
                            Start for free
            </a>
                        <p className="text-sm text-gray-600">
                            Forever free plan. Feature availablity subject to change.
                        </p>
                    </div>
                </div>
                <div className="flex flex-col justify-between p-5 bg-white border rounded shadow-sm">
                    <div className="mb-6">
                        <div className="flex items-center justify-between pb-6 mb-6 border-b">
                            <div>
                                <p className="text-sm font-bold tracking-wider uppercase">
                                    Upgrade for the best
                </p>
                                <p className="text-5xl font-extrabold">$2.99/mo</p>
                            </div>
                        </div>
                        <div>
                            <p className="mb-2 font-bold tracking-wide">Features</p>
                            <ul className="space-y-2">
                                <li className="flex items-center">
                                    <div className="mr-2">
                                        <svg
                                            className="w-4 h-4 text-deep-purple-accent-400"
                                            viewBox="0 0 24 24"
                                            strokeLinecap="round"
                                            strokeLidth="2"
                                        >
                                            <polyline
                                                fill="none"
                                                stroke="currentColor"
                                                points="6,12 10,16 18,8"
                                            />
                                            <circle
                                                cx="12"
                                                cy="12"
                                                fill="none"
                                                r="11"
                                                stroke="currentColor"
                                            />
                                        </svg>
                                    </div>
                                    <p className="font-medium text-gray-800">All the free plan features</p>
                                </li>
                                <li className="flex items-center">
                                    <div className="mr-2">
                                        <svg
                                            className="w-4 h-4 text-deep-purple-accent-400"
                                            viewBox="0 0 24 24"
                                            strokeLinecap="round"
                                            strokeLidth="2"
                                        >
                                            <polyline
                                                fill="none"
                                                stroke="currentColor"
                                                points="6,12 10,16 18,8"
                                            />
                                            <circle
                                                cx="12"
                                                cy="12"
                                                fill="none"
                                                r="11"
                                                stroke="currentColor"
                                            />
                                        </svg>
                                    </div>
                                    <p className="font-medium text-gray-800">
                                        Unlimited, Restriction Free Spaces
                  </p>
                                </li>
                                <li className="flex items-center">
                                    <div className="mr-2">
                                        <svg
                                            className="w-4 h-4 text-deep-purple-accent-400"
                                            viewBox="0 0 24 24"
                                            strokeLinecap="round"
                                            strokeLidth="2"
                                        >
                                            <polyline
                                                fill="none"
                                                stroke="currentColor"
                                                points="6,12 10,16 18,8"
                                            />
                                            <circle
                                                cx="12"
                                                cy="12"
                                                fill="none"
                                                r="11"
                                                stroke="currentColor"
                                            />
                                        </svg>
                                    </div>
                                    <p className="font-medium text-gray-800">Watch Together Spaces and more</p>
                                </li>
                                <li className="flex items-center">
                                    <div className="mr-2">
                                        <svg
                                            className="w-4 h-4 text-deep-purple-accent-400"
                                            viewBox="0 0 24 24"
                                            strokeLinecap="round"
                                            strokeLidth="2"
                                        >
                                            <polyline
                                                fill="none"
                                                stroke="currentColor"
                                                points="6,12 10,16 18,8"
                                            />
                                            <circle
                                                cx="12"
                                                cy="12"
                                                fill="none"
                                                r="11"
                                                stroke="currentColor"
                                            />
                                        </svg>
                                    </div>
                                    <p className="font-medium text-gray-800">Unlimited Circles</p>
                                </li>
                                <li className="flex items-center">
                                    <div className="mr-2">
                                        <svg
                                            className="w-4 h-4 text-deep-purple-accent-400"
                                            viewBox="0 0 24 24"
                                            strokeLinecap="round"
                                            strokeLidth="2"
                                        >
                                            <polyline
                                                fill="none"
                                                stroke="currentColor"
                                                points="6,12 10,16 18,8"
                                            />
                                            <circle
                                                cx="12"
                                                cy="12"
                                                fill="none"
                                                r="11"
                                                stroke="currentColor"
                                            />
                                        </svg>
                                    </div>
                                    <p className="font-medium text-gray-800">Unlimited Pages</p>
                                </li>
                                <li className="flex items-center">
                                    <div className="mr-2">
                                        <svg
                                            className="w-4 h-4 text-deep-purple-accent-400"
                                            viewBox="0 0 24 24"
                                            strokeLinecap="round"
                                            strokeLidth="2"
                                        >
                                            <polyline
                                                fill="none"
                                                stroke="currentColor"
                                                points="6,12 10,16 18,8"
                                            />
                                            <circle
                                                cx="12"
                                                cy="12"
                                                fill="none"
                                                r="11"
                                                stroke="currentColor"
                                            />
                                        </svg>
                                    </div>
                                    <p className="font-medium text-gray-800">Early Access to new features</p>
                                </li>
                                <li className="flex items-center">
                                    <div className="mr-2">
                                        <svg
                                            className="w-4 h-4 text-deep-purple-accent-400"
                                            viewBox="0 0 24 24"
                                            strokeLinecap="round"
                                            strokeLidth="2"
                                        >
                                            <polyline
                                                fill="none"
                                                stroke="currentColor"
                                                points="6,12 10,16 18,8"
                                            />
                                            <circle
                                                cx="12"
                                                cy="12"
                                                fill="none"
                                                r="11"
                                                stroke="currentColor"
                                            />
                                        </svg>
                                    </div>
                                    <p className="font-medium text-gray-800">Support Development</p>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div>
                        <a
                            href="/#"
                            className="inline-flex items-center justify-center w-full h-12 px-6 mb-4 font-medium tracking-wide text-white transition duration-200 rounded shadow-md bg-purple-500 hover:bg-purple-700 focus:shadow-outline focus:outline-none"
                        >
                            Get started
            </a>
                        <p className="text-sm text-gray-600">
                            Price at $2.99 per month or equivalent based on location
            </p>
                    </div>
                </div>
            </div>
        </div>
    );
};