import React from 'react';

const PrivacyPolicy = () => {
    return (
        <div className="p-5">
            <p><span style={{ fontWeight:400 }}>Effective date:&nbsp;18/04/2021</span></p>
            <p><span style={{ fontWeight:400 }}>&nbsp;</span></p>
            <p>&nbsp;</p>
            <ul>
                <li><strong><strong>Introduction</strong></strong></li>
            </ul>
            <p>&nbsp;</p>
            <p><strong>&nbsp;</strong></p>
            <p><span style={{ fontWeight:400 }}>Welcome to&nbsp;</span><strong>Igloo Social</strong><span style={{ fontWeight:400 }}>.</span></p>
            <p><span style={{ fontWeight:400 }}>&nbsp;</span></p>
            <p><span style={{ fontWeight:400 }}>Igloo Social&nbsp;(&ldquo;us&rdquo;, &ldquo;we&rdquo;, or &ldquo;our&rdquo;) operates&nbsp;Igloo mobile application&nbsp;(hereinafter referred to as &ldquo;</span><strong>Service</strong><span style={{ fontWeight:400 }}>&rdquo;).</span></p>
            <p><span style={{ fontWeight:400 }}>&nbsp;</span></p>
            <p><span style={{ fontWeight:400 }}>Our Privacy Policy governs your visit to&nbsp;Igloo mobile application, and explains how we collect, safeguard and disclose information that results from your use of our Service.&nbsp;</span></p>
            <p><span style={{ fontWeight:400 }}>&nbsp;</span></p>
            <p><span style={{ fontWeight:400 }}>We use your data to provide and improve Service. By using Service, you agree to the collection and use of information in accordance with this policy. Unless otherwise defined in this Privacy Policy, the terms used in this Privacy Policy have the same meanings as in our Terms and Conditions.</span></p>
            <p><span style={{ fontWeight:400 }}>&nbsp;</span></p>
            <p><span style={{ fontWeight:400 }}>Our Terms and Conditions (&ldquo;</span><strong>Terms</strong><span style={{ fontWeight:400 }}>&rdquo;) govern all use of our Service and together with the Privacy Policy constitutes your agreement with us (&ldquo;</span><strong>agreement</strong><span style={{ fontWeight:400 }}>&rdquo;).</span></p>
            <p><span style={{ fontWeight:400 }}>&nbsp;</span></p>
            <p>&nbsp;</p>
            <ul>
                <li><strong><strong>Definitions</strong></strong></li>
            </ul>
            <p>&nbsp;</p>
            <p><strong>&nbsp;</strong></p>
            <p><strong>SERVICE&nbsp;</strong><span style={{ fontWeight:400 }}>means the&nbsp;Igloo mobile application&nbsp;operated by&nbsp;Igloo Social.</span></p>
            <p><span style={{ fontWeight:400 }}>&nbsp;</span></p>
            <p><strong>PERSONAL DATA</strong><span style={{ fontWeight:400 }}>&nbsp;means data about a living individual who can be identified from those data (or from those and other information either in our possession or likely to come into our possession).</span></p>
            <p><span style={{ fontWeight:400 }}>&nbsp;</span></p>
            <p><strong>USAGE DATA</strong><span style={{ fontWeight:400 }}>&nbsp;is data collected automatically either generated by the use of Service or from Service infrastructure itself (for example, the duration of a page visit).</span></p>
            <p><span style={{ fontWeight:400 }}>&nbsp;</span></p>
            <p><strong>COOKIES</strong><span style={{ fontWeight:400 }}>&nbsp;are small files stored on your device (computer or mobile device).</span></p>
            <p><span style={{ fontWeight:400 }}>&nbsp;</span></p>
            <p><strong>DATA CONTROLLER</strong><span style={{ fontWeight:400 }}>&nbsp;means a natural or legal person who (either alone or jointly or in common with other persons) determines the purposes for which and the manner in which any personal data are, or are to be, processed. For the purpose of this Privacy Policy, we are a Data Controller of your data.</span></p>
            <p><span style={{ fontWeight:400 }}>&nbsp;</span></p>
            <p><strong>DATA PROCESSORS (OR SERVICE PROVIDERS)</strong><span style={{ fontWeight:400 }}>&nbsp;means any natural or legal person who processes the data on behalf of the Data Controller. We may use the services of various Service Providers in order to process your data more effectively.</span></p>
            <p><span style={{ fontWeight:400 }}>&nbsp;</span></p>
            <p><strong>DATA SUBJECT&nbsp;</strong><span style={{ fontWeight:400 }}>is any living individual who is the subject of Personal Data.</span></p>
            <p><span style={{ fontWeight:400 }}>&nbsp;</span></p>
            <p><strong>THE USER&nbsp;</strong><span style={{ fontWeight:400 }}>is the individual using our Service. The User corresponds to the Data Subject, who is the subject of Personal Data.</span></p>
            <p><span style={{ fontWeight:400 }}>&nbsp;</span></p>
            <p>&nbsp;</p>
            <ul>
                <li><strong><strong>Information Collection and Use</strong></strong></li>
            </ul>
            <p>&nbsp;</p>
            <p><strong>&nbsp;</strong></p>
            <p><span style={{ fontWeight:400 }}>We collect several different types of information for various purposes to provide and improve our Service to you.</span></p>
            <p><span style={{ fontWeight:400 }}>&nbsp;</span></p>
            <p>&nbsp;</p>
            <ul>
                <li><strong><strong>Types of Data Collected</strong></strong></li>
            </ul>
            <p>&nbsp;</p>
            <p><strong>&nbsp;</strong></p>
            <p><strong>Personal Data</strong></p>
            <p><span style={{ fontWeight:400 }}>While using our Service, we may ask you to provide us with certain personally identifiable information that can be used to contact or identify you (&ldquo;</span><strong>Personal Data</strong><span style={{ fontWeight:400 }}>&rdquo;). Personally identifiable information may include, but is not limited to:</span></p>
            <p><span style={{ fontWeight:400 }}>&nbsp;</span></p>
            <ol>
                <li style={{ fontWeight:400 }}><span style={{ fontWeight:400 }}>Email address</span></li>
            </ol>
            <p><span style={{ fontWeight:400 }}>&nbsp;</span></p>
            <ol>
                <li style={{ fontWeight:400 }}><span style={{ fontWeight:400 }}>First name and last name</span></li>
            </ol>
            <p><span style={{ fontWeight:400 }}>&nbsp;</span></p>
            <p><span style={{ fontWeight:400 }}>We may use your Personal Data to contact you with newsletters, marketing or promotional materials and other information that may be of interest to you. You may opt out of receiving any, or all, of these communications from us&nbsp;by following the unsubscribe link.</span></p>
            <p><span style={{ fontWeight:400 }}>&nbsp;</span></p>
            <p><strong>Usage Data</strong></p>
            <p><span style={{ fontWeight:400 }}>We may also collect information that your browser sends whenever you visit our Service or when you access Service by or through a mobile device (&ldquo;</span><strong>Usage Data</strong><span style={{ fontWeight:400 }}>&rdquo;).</span></p>
            <p><span style={{ fontWeight:400 }}>&nbsp;</span></p>
            <p><span style={{ fontWeight:400 }}>This Usage Data may include information such as your computer's Internet Protocol address (e.g. IP address), browser type, browser version, the pages of our Service that you visit, the time and date of your visit, the time spent on those pages, unique device identifiers and other diagnostic data.</span></p>
            <p><span style={{ fontWeight:400 }}>&nbsp;</span></p>
            <p><span style={{ fontWeight:400 }}>When you access Service with a mobile device, this Usage Data may include information such as the type of mobile device you use, your mobile device unique ID, the IP address of your mobile device, your mobile operating system, the type of mobile Internet browser you use, unique device identifiers and other diagnostic data.</span></p>
            <p><span style={{ fontWeight:400 }}>&nbsp;</span></p>
            <p><strong>Tracking Cookies Data</strong></p>
            <p><span style={{ fontWeight:400 }}>We use cookies and similar tracking technologies to track the activity on our Service and we hold certain information.</span></p>
            <p><span style={{ fontWeight:400 }}>&nbsp;</span></p>
            <p><span style={{ fontWeight:400 }}>Cookies are files with a small amount of data which may include an anonymous unique identifier. Cookies are sent to your browser from a website and stored on your device. Other tracking technologies are also used such as beacons, tags and scripts to collect and track information and to improve and analyze our Service.</span></p>
            <p><span style={{ fontWeight:400 }}>&nbsp;</span></p>
            <p><span style={{ fontWeight:400 }}>You can instruct your browser to refuse all cookies or to indicate when a cookie is being sent. However, if you do not accept cookies, you may not be able to use some portions of our Service.</span></p>
            <p><span style={{ fontWeight:400 }}>&nbsp;</span></p>
            <p><span style={{ fontWeight:400 }}>Examples of Cookies we use:</span></p>
            <p><span style={{ fontWeight:400 }}>&nbsp;</span></p>
            <ol>
                <li style={{ fontWeight:400 }}><strong>Session Cookies:</strong><span style={{ fontWeight:400 }}>&nbsp;We use Session Cookies to operate our Service.</span></li>
            </ol>
            <p><span style={{ fontWeight:400 }}>&nbsp;</span></p>
            <ol>
                <li style={{ fontWeight:400 }}><strong>Preference Cookies:</strong><span style={{ fontWeight:400 }}>&nbsp;We use Preference Cookies to remember your preferences and various settings.</span></li>
            </ol>
            <p><span style={{ fontWeight:400 }}>&nbsp;</span></p>
            <ol>
                <li style={{ fontWeight:400 }}><strong>Security Cookies:</strong><span style={{ fontWeight:400 }}>&nbsp;We use Security Cookies for security purposes.</span></li>
            </ol>
            <p><span style={{ fontWeight:400 }}>&nbsp;</span></p>
            <p>&nbsp;</p>
            <ul>
                <li><strong><strong>Use of Data</strong></strong></li>
            </ul>
            <p>&nbsp;</p>
            <p><strong>&nbsp;</strong></p>
            <p><span style={{ fontWeight:400 }}>Igloo Social&nbsp;uses the collected data for various purposes:</span></p>
            <p><span style={{ fontWeight:400 }}>&nbsp;</span></p>
            <ol>
                <li style={{ fontWeight:400 }}><span style={{ fontWeight:400 }}>to provide and maintain our Service;</span></li>
            </ol>
            <p><span style={{ fontWeight:400 }}>&nbsp;</span></p>
            <ol>
                <li style={{ fontWeight:400 }}><span style={{ fontWeight:400 }}>to notify you about changes to our Service;&nbsp;</span></li>
            </ol>
            <p><span style={{ fontWeight:400 }}>&nbsp;</span></p>
            <ol>
                <li style={{ fontWeight:400 }}><span style={{ fontWeight:400 }}>to allow you to participate in interactive features of our Service when you choose to do so;&nbsp;</span></li>
            </ol>
            <p><span style={{ fontWeight:400 }}>&nbsp;</span></p>
            <ol>
                <li style={{ fontWeight:400 }}><span style={{ fontWeight:400 }}>to provide customer support;&nbsp;</span></li>
            </ol>
            <p><span style={{ fontWeight:400 }}>&nbsp;</span></p>
            <ol>
                <li style={{ fontWeight:400 }}><span style={{ fontWeight:400 }}>to gather analysis or valuable information so that we can improve our Service;&nbsp;</span></li>
            </ol>
            <p><span style={{ fontWeight:400 }}>&nbsp;</span></p>
            <ol>
                <li style={{ fontWeight:400 }}><span style={{ fontWeight:400 }}>to monitor the usage of our Service;</span></li>
            </ol>
            <p><span style={{ fontWeight:400 }}>&nbsp;</span></p>
            <ol>
                <li style={{ fontWeight:400 }}><span style={{ fontWeight:400 }}>to detect, prevent and address technical issues;</span></li>
            </ol>
            <p><span style={{ fontWeight:400 }}>&nbsp;</span></p>
            <ol>
                <li style={{ fontWeight:400 }}><span style={{ fontWeight:400 }}>to fulfill any other purpose for which you provide it;</span></li>
            </ol>
            <p><span style={{ fontWeight:400 }}>&nbsp;</span></p>
            <ol>
                <li style={{ fontWeight:400 }}><span style={{ fontWeight:400 }}>to carry out our obligations and enforce our rights arising from any contracts entered into between you and us, including for billing and collection;</span></li>
            </ol>
            <p><span style={{ fontWeight:400 }}>&nbsp;</span></p>
            <ol>
                <li style={{ fontWeight:400 }}><span style={{ fontWeight:400 }}>to provide you with notices about your account and/or subscription, including expiration and renewal notices, email-instructions, etc.;</span></li>
            </ol>
            <p><span style={{ fontWeight:400 }}>&nbsp;</span></p>
            <ol>
                <li style={{ fontWeight:400 }}><span style={{ fontWeight:400 }}>to provide you with news, special offers and general information about other goods, services and events which we offer that are similar to those that you have already purchased or enquired about unless you have opted not to receive such information;</span></li>
            </ol>
            <p><span style={{ fontWeight:400 }}>&nbsp;</span></p>
            <ol>
                <li style={{ fontWeight:400 }}><span style={{ fontWeight:400 }}>in any other way we may describe when you provide the information;</span></li>
            </ol>
            <p><span style={{ fontWeight:400 }}>&nbsp;</span></p>
            <ol>
                <li style={{ fontWeight:400 }}><span style={{ fontWeight:400 }}>for any other purpose with your consent.&nbsp;</span></li>
            </ol>
            <p><span style={{ fontWeight:400 }}>&nbsp;</span></p>
            <p>&nbsp;</p>
            <ul>
                <li><strong><strong>Retention of Data</strong></strong></li>
            </ul>
            <p>&nbsp;</p>
            <p><strong>&nbsp;</strong></p>
            <p><span style={{ fontWeight:400 }}>We will retain your Personal Data only for as long as is necessary for the purposes set out in this Privacy Policy. We will retain and use your Personal Data to the extent necessary to comply with our legal obligations (for example, if we are required to retain your data to comply with applicable laws), resolve disputes, and enforce our legal agreements and policies.</span></p>
            <p><span style={{ fontWeight:400 }}>&nbsp;</span></p>
            <p><span style={{ fontWeight:400 }}>We will also retain Usage Data for internal analysis purposes. Usage Data is generally retained for a shorter period, except when this data is used to strengthen the security or to improve the functionality of our Service, or we are legally obligated to retain this data for longer time periods.</span></p>
            <p><span style={{ fontWeight:400 }}>&nbsp;</span></p>
            <p>&nbsp;</p>
            <ul>
                <li><strong><strong>Transfer of Data</strong></strong></li>
            </ul>
            <p>&nbsp;</p>
            <p><strong>&nbsp;</strong></p>
            <p><span style={{ fontWeight:400 }}>Your information, including Personal Data, may be transferred to &ndash; and maintained on &ndash; computers located outside of your state, province, country or other governmental jurisdiction where the data protection laws may differ from those of your jurisdiction.</span></p>
            <p><span style={{ fontWeight:400 }}>&nbsp;</span></p>
            <p><span style={{ fontWeight:400 }}>If you are located outside United States and choose to provide information to us, please note that we transfer the data, including Personal Data, to United States and process it there.</span></p>
            <p><span style={{ fontWeight:400 }}>&nbsp;</span></p>
            <p><span style={{ fontWeight:400 }}>Your consent to this Privacy Policy followed by your submission of such information represents your agreement to that transfer.</span></p>
            <p><span style={{ fontWeight:400 }}>&nbsp;</span></p>
            <p><span style={{ fontWeight:400 }}>Igloo Social&nbsp;will take all the steps reasonably necessary to ensure that your data is treated securely and in accordance with this Privacy Policy and no transfer of your Personal Data will take place to an organisation or a country unless there are adequate controls in place including the security of your data and other personal information.</span></p>
            <p><span style={{ fontWeight:400 }}>&nbsp;</span></p>
            <p>&nbsp;</p>
            <ul>
                <li><strong><strong>Disclosure of Data</strong></strong></li>
            </ul>
            <p>&nbsp;</p>
            <p><strong>&nbsp;</strong></p>
            <p><span style={{ fontWeight:400 }}>We may disclose personal information that we collect, or you provide:</span></p>
            <p><span style={{ fontWeight:400 }}>&nbsp;</span></p>
            <p>&nbsp;</p>
            <ul>
                <li><strong><strong>Disclosure for Law Enforcement.</strong></strong></li>
            </ul>
            <p>&nbsp;</p>
            <p><span style={{ fontWeight:400 }}>Under certain circumstances, we may be required to disclose your Personal Data if required to do so by law or in response to valid requests by public authorities.</span></p>
            <p><span style={{ fontWeight:400 }}>&nbsp;</span></p>
            <p>&nbsp;</p>
            <ul>
                <li><strong><strong>Business Transaction.</strong></strong></li>
            </ul>
            <p>&nbsp;</p>
            <p><span style={{ fontWeight:400 }}>If we or our subsidiaries are involved in a merger, acquisition or asset sale, your Personal Data may be transferred.</span></p>
            <p><span style={{ fontWeight:400 }}>&nbsp;</span></p>
            <p>&nbsp;</p>
            <ul>
                <li><strong><strong>Other cases. We may disclose your information also:</strong></strong></li>
            </ul>
            <p>&nbsp;</p>
            <p><strong>&nbsp;</strong></p>
            <ol>
                <li style={{ fontWeight:400 }}><span style={{ fontWeight:400 }}>to fulfill the purpose for which you provide it;</span></li>
            </ol>
            <p><span style={{ fontWeight:400 }}>&nbsp;</span></p>
            <ol>
                <li style={{ fontWeight:400 }}><span style={{ fontWeight:400 }}>for any other purpose disclosed by us when you provide the information;</span></li>
            </ol>
            <p><span style={{ fontWeight:400 }}>&nbsp;</span></p>
            <ol>
                <li style={{ fontWeight:400 }}><span style={{ fontWeight:400 }}>with your consent in any other cases;</span></li>
            </ol>
            <p><span style={{ fontWeight:400 }}>&nbsp;</span></p>
            <ol>
                <li style={{ fontWeight:400 }}><span style={{ fontWeight:400 }}>if we believe disclosure is necessary or appropriate to protect the rights, property, or safety of the Company, our customers, or others.</span></li>
            </ol>
            <p><span style={{ fontWeight:400 }}>&nbsp;</span></p>
            <p>&nbsp;</p>
            <ul>
                <li><strong><strong>Security of Data</strong></strong></li>
            </ul>
            <p>&nbsp;</p>
            <p><strong>&nbsp;</strong></p>
            <p><span style={{ fontWeight:400 }}>The security of your data is important to us but remember that no method of transmission over the Internet or method of electronic storage is 100% secure. While we strive to use commercially acceptable means to protect your Personal Data, we cannot guarantee its absolute security.</span></p>
            <p><span style={{ fontWeight:400 }}>&nbsp;</span></p>
            <p>&nbsp;</p>
            <ul>
                <li><strong><strong>Your Data Protection Rights Under General Data Protection Regulation (GDPR)</strong></strong></li>
            </ul>
            <p>&nbsp;</p>
            <p><strong>&nbsp;</strong></p>
            <p><span style={{ fontWeight:400 }}>If you are a resident of the European Union (EU) and European Economic Area (EEA), you have certain data protection rights, covered by GDPR. &ndash; See more at https://eur-lex.europa.eu/eli/reg/2016/679/oj&nbsp;</span></p>
            <p><span style={{ fontWeight:400 }}>&nbsp;</span></p>
            <p><span style={{ fontWeight:400 }}>We aim to take reasonable steps to allow you to correct, amend, delete, or limit the use of your Personal Data.</span></p>
            <p><span style={{ fontWeight:400 }}>&nbsp;</span></p>
            <p><span style={{ fontWeight:400 }}>If you wish to be informed what Personal Data we hold about you and if you want it to be removed from our systems, please email us at support@igloosocial.com.&nbsp;</span></p>
            <p><span style={{ fontWeight:400 }}>&nbsp;</span></p>
            <p><span style={{ fontWeight:400 }}>In certain circumstances, you have the following data protection rights:</span></p>
            <p><span style={{ fontWeight:400 }}>&nbsp;</span></p>
            <ol>
                <li style={{ fontWeight:400 }}><span style={{ fontWeight:400 }}>the right to access, update or to delete the information we have on you;</span></li>
            </ol>
            <p><span style={{ fontWeight:400 }}>&nbsp;</span></p>
            <ol>
                <li style={{ fontWeight:400 }}><span style={{ fontWeight:400 }}>the right of rectification. You have the right to have your information rectified if that information is inaccurate or incomplete;</span></li>
            </ol>
            <p><span style={{ fontWeight:400 }}>&nbsp;</span></p>
            <ol>
                <li style={{ fontWeight:400 }}><span style={{ fontWeight:400 }}>the right to object. You have the right to object to our processing of your Personal Data;</span></li>
            </ol>
            <p><span style={{ fontWeight:400 }}>&nbsp;</span></p>
            <ol>
                <li style={{ fontWeight:400 }}><span style={{ fontWeight:400 }}>the right of restriction. You have the right to request that we restrict the processing of your personal information;</span></li>
            </ol>
            <p><span style={{ fontWeight:400 }}>&nbsp;</span></p>
            <ol>
                <li style={{ fontWeight:400 }}><span style={{ fontWeight:400 }}>the right to data portability. You have the right to be provided with a copy of your Personal Data in a structured, machine-readable and commonly used format;</span></li>
            </ol>
            <p><span style={{ fontWeight:400 }}>&nbsp;</span></p>
            <ol>
                <li style={{ fontWeight:400 }}><span style={{ fontWeight:400 }}>the right to withdraw consent.&nbsp;You also have the right to withdraw your consent at any time where we rely on your consent to process your personal information;</span></li>
            </ol>
            <p><span style={{ fontWeight:400 }}>&nbsp;</span></p>
            <p><span style={{ fontWeight:400 }}>Please note that we may ask you to verify your identity before responding to such requests. Please note, we may not able to provide Service without some necessary data.</span></p>
            <p><span style={{ fontWeight:400 }}>&nbsp;</span></p>
            <p><span style={{ fontWeight:400 }}>You have the right to complain to a Data Protection Authority about our collection and use of your Personal Data. For more information, please contact your local data protection authority in the European Economic Area (EEA).</span></p>
            <p><span style={{ fontWeight:400 }}>&nbsp;</span></p>
            <p>&nbsp;</p>
            <ul>
                <li><strong><strong>Your Data Protection Rights under the California Privacy Protection Act (CalOPPA)</strong></strong></li>
            </ul>
            <p>&nbsp;</p>
            <p><strong>&nbsp;</strong></p>
            <p><span style={{ fontWeight:400 }}>CalOPPA is the first state law in the nation to require commercial websites and online services to post a privacy policy. The law&rsquo;s reach stretches well beyond California to require a person or company in the United States (and conceivable the world) that operates websites collecting personally identifiable information from California consumers to post a conspicuous privacy policy on its website stating exactly the information being collected and those individuals with whom it is being shared, and to comply with this policy. &ndash; See more at: https://consumercal.org/about-cfc/cfc-education-foundation/california-online-privacy-protection-act-caloppa-3/&nbsp;</span></p>
            <p><span style={{ fontWeight:400 }}>&nbsp;</span></p>
            <p><span style={{ fontWeight:400 }}>According to CalOPPA we agree to the following:</span></p>
            <p><span style={{ fontWeight:400 }}>&nbsp;</span></p>
            <ol>
                <li style={{ fontWeight:400 }}><span style={{ fontWeight:400 }}>users can visit our site anonymously;</span></li>
            </ol>
            <p><span style={{ fontWeight:400 }}>&nbsp;</span></p>
            <ol>
                <li style={{ fontWeight:400 }}><span style={{ fontWeight:400 }}>our Privacy Policy link includes the word &ldquo;Privacy&rdquo;, and can easily be found on the page specified above on the home page of our website;</span></li>
            </ol>
            <p><span style={{ fontWeight:400 }}>&nbsp;</span></p>
            <ol>
                <li style={{ fontWeight:400 }}><span style={{ fontWeight:400 }}>users will be notified of any privacy policy changes on our Privacy Policy Page;</span></li>
            </ol>
            <p><span style={{ fontWeight:400 }}>&nbsp;</span></p>
            <ol>
                <li style={{ fontWeight:400 }}><span style={{ fontWeight:400 }}>users are able to change their personal information by emailing us at support@igloosocial.com.&nbsp;</span></li>
            </ol>
            <p><span style={{ fontWeight:400 }}>&nbsp;</span></p>
            <p><span style={{ fontWeight:400 }}>Our Policy on &ldquo;Do Not Track&rdquo; Signals:</span></p>
            <p><span style={{ fontWeight:400 }}>&nbsp;</span></p>
            <p><span style={{ fontWeight:400 }}>We honor Do Not Track signals and do not track, plant cookies, or use advertising when a Do Not Track browser mechanism is in place. Do Not Track is a preference you can set in your web browser to inform websites that you do not want to be tracked.&nbsp;</span></p>
            <p><span style={{ fontWeight:400 }}>&nbsp;</span></p>
            <p><span style={{ fontWeight:400 }}>You can enable or disable Do Not Track by visiting the Preferences or Settings page of your web browser.</span></p>
            <p><span style={{ fontWeight:400 }}>&nbsp;</span></p>
            <p>&nbsp;</p>
            <ul>
                <li><strong><strong>Service Providers</strong></strong></li>
            </ul>
            <p>&nbsp;</p>
            <p><strong>&nbsp;</strong></p>
            <p><span style={{ fontWeight:400 }}>We may employ third party companies and individuals to facilitate our Service (&ldquo;</span><strong>Service Providers</strong><span style={{ fontWeight:400 }}>&rdquo;), provide Service on our behalf, perform Service-related services or assist us in analysing how our Service is used.</span></p>
            <p><span style={{ fontWeight:400 }}>&nbsp;</span></p>
            <p><span style={{ fontWeight:400 }}>These third parties have access to your Personal Data only to perform these tasks on our behalf and are obligated not to disclose or use it for any other purpose.</span></p>
            <p><span style={{ fontWeight:400 }}>&nbsp;</span></p>
            <p>&nbsp;</p>
            <ul>
                <li><strong><strong>CI/CD tools</strong></strong></li>
            </ul>
            <p>&nbsp;</p>
            <p><span style={{ fontWeight:400 }}>&nbsp;</span></p>
            <p><span style={{ fontWeight:400 }}>We may use third-party Service Providers to automate the development process of our Service.&nbsp;</span></p>
            <p><strong>&nbsp;</strong></p>
            <p><strong>GitHub</strong></p>
            <p><span style={{ fontWeight:400 }}>&nbsp;</span></p>
            <p><span style={{ fontWeight:400 }}>GitHub is provided by GitHub, Inc.</span></p>
            <p><span style={{ fontWeight:400 }}>&nbsp;</span></p>
            <p><span style={{ fontWeight:400 }}>GitHub is a development platform to host and review code, manage projects, and build software.</span></p>
            <p><span style={{ fontWeight:400 }}>&nbsp;</span></p>
            <p><span style={{ fontWeight:400 }}>For more information on what data GitHub collects for what purpose and how the protection of the data is ensured, please visit GitHub Privacy Policy page:</span> <a href="https://help.github.com/en/articles/github-privacy-statement"><span style={{ fontWeight:400 }}>https://help.github.com/en/articles/github-privacy-statement.</span></a></p>
            <p><strong>&nbsp;</strong></p>
            <p>&nbsp;</p>
            <ul>
                <li><strong><strong>Payments</strong></strong></li>
            </ul>
            <p>&nbsp;</p>
            <p><strong>&nbsp;</strong></p>
            <p><span style={{ fontWeight:400 }}>We may provide paid products and/or services within Service. In that case, we use third-party services for payment processing (e.g. payment processors).</span></p>
            <p><span style={{ fontWeight:400 }}>&nbsp;</span></p>
            <p><span style={{ fontWeight:400 }}>We will not store or collect your payment card details. That information is provided directly to our third-party payment processors whose use of your personal information is governed by their Privacy Policy. These payment processors adhere to the standards set by PCI-DSS as managed by the PCI Security Standards Council, which is a joint effort of brands like Visa, Mastercard, American Express and Discover. PCI-DSS requirements help ensure the secure handling of payment information.</span></p>
            <p><span style={{ fontWeight:400 }}>&nbsp;</span></p>
            <p><span style={{ fontWeight:400 }}>The payment processors we work with are:</span></p>
            <p><span style={{ fontWeight:400 }}>&nbsp;</span></p>
            <p><strong>Apple Store In-App Payments:</strong></p>
            <p><span style={{ fontWeight:400 }}>Their Privacy Policy can be viewed at: https://www.apple.com/legal/privacy/en-ww/ / https://support.apple.com/en-us/HT203027&nbsp;</span></p>
            <p><span style={{ fontWeight:400 }}>&nbsp;</span></p>
            <p><strong>Google Play In-App Payments:</strong></p>
            <p><span style={{ fontWeight:400 }}>Their Privacy Policy can be viewed at: https://policies.google.com/privacy?hl=en&amp;gl=us / https://payments.google.com/payments/apis-secure/u/0/get_legal_document?ldo=0&amp;ldt=privacynotice&amp;ldl=en&nbsp;</span></p>
            <p><span style={{ fontWeight:400 }}>&nbsp;</span></p>
            <p>&nbsp;</p>
            <ul>
                <li><strong><strong>Links to Other Sites</strong></strong></li>
            </ul>
            <p>&nbsp;</p>
            <p><strong>&nbsp;</strong></p>
            <p><span style={{ fontWeight:400 }}>Our Service may contain links to other sites that are not operated by us. If you click a third party link, you will be directed to that third party's site. We strongly advise you to review the Privacy Policy of every site you visit.</span></p>
            <p><strong>&nbsp;</strong></p>
            <p><span style={{ fontWeight:400 }}>We have no control over and assume no responsibility for the content, privacy policies or practices of any third party sites or services.</span></p>
            <p><span style={{ fontWeight:400 }}>&nbsp;</span></p>
            <p>&nbsp;</p>
            <ul>
                <li><strong><strong>Children's Privacy</strong></strong></li>
            </ul>
            <p>&nbsp;</p>
            <p><span style={{ fontWeight:400 }}>&nbsp;</span></p>
            <p><span style={{ fontWeight:400 }}>Our Services are not intended for use by children under the age of 13 (&ldquo;</span><strong>Children</strong><span style={{ fontWeight:400 }}>&rdquo;).&nbsp;</span></p>
            <p><span style={{ fontWeight:400 }}>&nbsp;</span></p>
            <p><span style={{ fontWeight:400 }}>We do not knowingly collect personally identifiable information from Children under 13. If you become aware that a Child has provided us with Personal Data, please contact us. If we become aware that we have collected Personal Data from Children without verification of parental consent, we take steps to remove that information from our servers.</span></p>
            <p><span style={{ fontWeight:400 }}>&nbsp;</span></p>
            <p>&nbsp;</p>
            <ul>
                <li><strong><strong>Changes to This Privacy Policy</strong></strong></li>
            </ul>
            <p>&nbsp;</p>
            <p><strong>&nbsp;</strong></p>
            <p><span style={{ fontWeight:400 }}>We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page.</span></p>
            <p><strong>&nbsp;</strong></p>
            <p><span style={{ fontWeight:400 }}>We will let you know via email and/or a prominent notice on our Service, prior to the change becoming effective and update &ldquo;effective date&rdquo; at the top of this Privacy Policy.</span></p>
            <p><span style={{ fontWeight:400 }}>&nbsp;</span></p>
            <p><span style={{ fontWeight:400 }}>You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page.</span></p>
            <p><span style={{ fontWeight:400 }}>&nbsp;</span></p>
            <p>&nbsp;</p>
            <ul>
                <li><strong><strong>Contact Us</strong></strong></li>
            </ul>
            <p>&nbsp;</p>
            <p><strong>&nbsp;</strong></p>
            <p><span style={{ fontWeight:400 }}>If you have any questions about this Privacy Policy, please contact us:</span></p>
            <p><span style={{ fontWeight:400 }}>&nbsp;</span></p>
            <p><span style={{ fontWeight:400 }}>By email: support@igloosocial.com.</span></p>
            <p><span style={{ fontWeight:400 }}>&nbsp;</span></p>
        </div>
    );
}

export default PrivacyPolicy;