const FAQ = () => {
    return (
        <div className="mt-16 w-full">
            <p className="text-3xl text-center text-gray-600 my-8">Frequently Asked Questions</p>
            <div className="ml-8 mt-4 p-2 mr-4">
                <p className="text-xl font-bold">How does Igloo make money?</p>
                <p className="text-lg">While Igloo is free to get started, many features and controls are locked behind a $2.99 subscription that helps sustain the service</p>
            </div>
            <div className="ml-8 mt-4 p-2 mr-4">
                <p className="text-xl font-bold">Can I access Igloo from the browser?</p>
                <p className="text-lg">A Web App is in the works and should hopefully be out soon. Till then, our <a href="https://play.google.com/store/apps/details?id=com.igloosocial.app" className="text-blue-700">Android</a> and <a href="https://apps.apple.com/us/app/igloo-private-social-media/id1560450829" className="text-blue-700">iOS</a> Apps are the way to go.</p>
            </div>
            <div className="ml-8 mt-4 p-2 mr-4">
                <p className="text-xl font-bold">Is Igloo Open Source?</p>
                <p className="text-lg">Igloo currently isn't open source but there are plans to open source client side code</p>
            </div>
            <div className="ml-8 mt-4 p-2 mr-4">
                <p className="text-xl font-bold">Why isn't Igloo Server Open Sourced?</p>
                <p className="text-lg">While I do want to open source as much as possible, the server code has some proprietary code for handling circles and spaces. Moreover, considering there's absolutely no way to verify if the server code is the same as the one open sourced one, it serves no purpose.</p>
            </div>
            <div className="ml-8 mt-4 p-2 mr-4">
                <p className="text-xl font-bold">Can I host Igloo on my own server?</p>
                <p className="text-lg">A decentralised version is in the works but no immediate plans for it. I've shared my thoughts on why it wouldn't work right now over here - <a href="https://blog.ashwinalaparthi.com/the-barriers-to-decentralisation/" className="text-blue-700">Barriers to Decentralisation</a></p>
            </div>
            <div className="ml-8 mt-4 p-2 mr-4">
                <p className="text-xl font-bold">How do I trust Igloo isn't selling my data?</p>
                <p className="text-lg">Honestly, I know there's no way to verify without being able to host your own instance. However, we have a strong privacy policy that outlines what data we log and that we don't share it with any third party. We show no ads, so we have no use for tracking you.</p>
            </div>
            <div className="ml-8 mt-4 p-2 mr-4">
                <p className="text-xl font-bold">Are Igloo Spaces recorded?</p>
                <p className="text-lg">No conversation on Igloo Spaces is recorded by us, and neither are the chat messages sent in a Space. If you find an issue with Spaces, please record if possible and send it over to us so we can take a look at it.</p>
            </div>
            <div className="ml-8 mt-4 p-2 mr-4 mb-8">
                <p className="text-xl font-bold">Where do I contact for help/feedback/suggestions?</p>
                <p className="text-lg">Reach out to us at <a href="mailto:support@igloosocial.com" className="text-blue-700">support@igloosocial.com</a> and we'll gladly help you out with any questions you might have!</p>
            </div>
            <footer class="bg-gray-50 text-gray-600 px-6 lg:px-8 py-6 md:py-12">
                <div class="max-w-screen-xl mx-auto grid md:grid-cols-2 lg:grid-cols-5 gap-6 lg:gap-x-8">
                    <div>
                        <h5 class="text-xl font-semibold text-gray-700">Igloo</h5>
                        <nav class="mt-4">
                            <ul class="space-y-2">
                                <li>
                                    <a href="/" class="font-normal text-base hover:text-gray-400">Go back to the top</a>
                                </li>
                                <li>
                                    <a href="/#features" class="font-normal text-base hover:text-gray-400">Features</a>
                                </li>
                                <li>
                                    <a href="/#getitnow" class="font-normal text-red-500 text-base hover:text-gray-400">Get it
                                now</a>
                                </li>
                            </ul>
                        </nav>
                    </div>
                    <div>
                        <h5 class="text-xl font-semibold text-gray-700">About us</h5>
                        <nav class="mt-4">
                            <ul class="space-y-2">
                                <li>
                                    <a href="/" class="font-normal text-base hover:text-gray-400">Company</a>
                                </li>
                                <li>
                                    <a href="/" class="font-normal text-base hover:text-gray-400">Media Kit</a>
                                </li>
                            </ul>
                        </nav>
                    </div>
                    <div>
                        <h5 class="text-xl font-semibold text-gray-700">Legal</h5>
                        <nav class="mt-4">
                            <ul class="space-y-2">
                                <li>
                                    <a href="/termsandconditions" class="font-normal text-base hover:text-gray-400">Terms and conditions</a>
                                </li>
                                <li>
                                    <a href="/privacypolicy" class="font-normal text-base hover:text-gray-400">Privacy and Cookie
                                Statement</a>
                                </li>
                            </ul>
                        </nav>
                    </div>

                    <div>
                        <h5 class="text-xl font-semibold text-gray-700">Contact</h5>
                        <div class="space-y-4 md:space-y-6 mt-4">
                            <div class="flex items-start space-x-4">
                                <div>
                                    <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6 mt-1 text-gray-500" fill="none"
                                        viewBox="0 0 24 24" stroke="currentColor">
                                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                            d="M8 12h.01M12 12h.01M16 12h.01M21 12c0 4.418-4.03 8-9 8a9.863 9.863 0 01-4.255-.949L3 20l1.395-3.72C3.512 15.042 3 13.574 3 12c0-4.418 4.03-8 9-8s9 3.582 9 8z" />
                                    </svg>
                                </div>
                                <div class="flex-1">
                                    <a class="not-italic" href="https://discord.gg/aBjSnvgs7a">
                                        Join our Discord Server
                            </a>
                                </div>
                            </div>
                            <div class="flex items-start space-x-4">
                                <div>
                                    <svg class="w-6 h-6 mt-1 text-gray-500" fill="none" stroke="currentColor"
                                        viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                            d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z">
                                        </path>
                                    </svg>
                                </div>
                                <div class="flex-1">
                                    <a href="mailto:support@igloosocial.com"
                                        class="hover:text-gray-500">support@igloosocial.com</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    class="max-w-screen-xl mx-auto flex flex-col md:flex-row justify-between items-center space-y-4 mt-16 lg:mt-20">
                    <img class="h-12" src="/assets/Iglogo.png" alt="Igloo logo" />
                    <div class="text-sm">
                        &copy;2021 Igloo. All rights reserved.
            </div>
                </div>
            </footer>
        </div>
    );
}

export default FAQ;