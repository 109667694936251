import './App.css';
import React from 'react';
import Post from './Pages/Post';
import User from './Pages/User';
import Page from './Pages/Page';
import Landing from './Pages/Landing';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Space from './Pages/Space';
import PrivacyPolicy from './Pages/PrivacyPolicy';
import TermsAndConditions from './Pages/TermsAndConditions';
import FAQ from './Pages/FAQ';
import TwitterVerification from './Pages/Twitter';

function App() {
  return (
    <Router>
      <Switch>
        <Route exact path="/" component={Landing} />
        <Route exact path="/post/:id" component={Post} />
        <Route exact path="/space/:id" component={Space} />
        <Route exact path="/user/:username" component={User} />
        <Route exact path="/page/:id" component={Page} />
        <Route exct path="/privacypolicy" component={PrivacyPolicy} />
        <Route exct path="/termsandconditions" component={TermsAndConditions} />
        <Route exct path="/twitterverification" component={TwitterVerification} />
        <Route exct path="/faq" component={FAQ} />
        <Route component={Landing} />
      </Switch>
    </Router>
  );
}

export default App;
