const Post = (props) => {
    return (
        <div>
            <h1 className="text-4xl tracking-tight font-extrabold text-gray-900 sm:text-5xl md:text-6xl text-center mt-16">
                <span className="block xl:inline">Welcome to Igloo</span>
            </h1>
            <div className="mt-6 tracking-tight font-extrabold text-gray-600 sm:text-4xl md:text-5xl text-center">
                The <span className="text-red-600 xl:inline">Private</span> Ad-Free Social Media
            </div>
            <div className="mt-16 flex flex-col lg:flex-row justify-center items-center align-center">
                <a href="https://play.google.com/store/apps/details?id=com.igloosocial.app">
                    <img className="h-15 w-48 m-2" src="/assets/google-play-badge.png" alt="Play Store Button" />
                </a>
                <a href="https://apps.apple.com/us/app/igloo-private-social-media/id1560450829">
                    <img className="h-15 w-48 m-2" src="/assets/App Store.svg" alt="App Store Button" />
                </a>
            </div>
            <div className="mt-16 px-12 text-center sm:text-3x md:text-4xl">
                Unfortunately Igloo is currently only available as a mobile app.<br />We're working hard to get a website as soon as possible.
            </div>
            <div className="lg:mt-16 mt-8 px-12 text-center sm:text-3xl md:text-4xl">
                To view the post, download the Igloo mobile app from the Play Store or App Store.
            </div>
            <div className="flex p-4 mt-5 flex-col justify-center" onClick={() => window.location.href="/#features"}>
                <div className="text-3xl md:text-4xl text-center">
                    Why use Igloo?
                    </div>
                <div className="text-center p-5 rounded-md mt-4 mx-4 md:mx-52 text-purple-600 border-grey shadow-sm hover:shadow-md hover:bg-gray-100 cursor-pointer bg-white">
                    <div className="text-xl md:text-2xl pt-2">
                        Check out all the reasons to shift to Igloo
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Post;