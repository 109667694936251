import axios from 'axios';
import React, { useState, useEffect } from 'react';


const Page = (props) => {

    let [page, setPage] = useState({});
    let [loading, setLoading] = useState(true);

    useEffect(() => {
        axios.post("/pages/getPageDetails", { pageId: props.match.params.id },)
            .then((res) => {
                setPage(res.data.pageDetails);
                setLoading(false);
            })
            .catch((err) => {
                console.log(err);
            })
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    if (loading === true) {
        return (
            <div className="flex flex-col absolute w-full h-full justify-center items-center">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" class="animate-spin h-16 w-16">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z" />
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                </svg>
                <br />
                Loading
            </div>
        );
    }

    return (
        <div className="flex flex-col justify-center items-center align-middle">
            <h1 className="text-4xl tracking-tight font-extrabold text-gray-900 sm:text-5xl md:text-6xl text-center mt-16">
                <span className="block xl:inline">Welcome to Igloo</span>
            </h1>
            <div className="mt-6 tracking-tight font-extrabold text-gray-600 sm:text-4xl md:text-5xl text-center">
                The <span className="text-red-600 xl:inline">Private</span> Ad-Free Social Media
            </div>
            <div id="userCard" className="p-5 shadow-lg w-3/4 md:w-1/3 rounded-md mt-5 flex flex-col justify-center align-middle items-center border">
                {/* <img alt={`Profile of ${page.name}`} src={user.profilePicture} className="rounded-full h-24" /> */}
                <p className="mt-2 text-gray-700 text-3xl font-bold">{page.name}</p>
                <p className="mt-2 text-gray-500 text-xl font-bold">A page by {page.creator.name}</p>
                <div className="mt-2 text-gray-400 text-md text-center">
                    {page.description.split("\n").map((i, key) => {
                        return <div key={key}>{i}</div>;
                    })}
                </div>
                <div className="mt-5 bg-red-500 hover:bg-red-700 cursor-pointer text-center p-2 rounded text-white font-bold" onClick={() => window.location.href = "#stores"}>
                    Get Igloo now to follow {page.name}
                </div>
            </div>
            <div id="stores" className="mt-16 flex flex-col lg:flex-row justify-center items-center align-center">
                <a href="https://play.google.com/store/apps/details?id=com.igloosocial.app">
                    <img className="h-15 w-48 m-2" src="/assets/google-play-badge.png" alt="Play Store Button" />
                </a>
                <a href="https://apps.apple.com/us/app/igloo-private-social-media/id1560450829">
                    <img className="h-15 w-48 m-2" src="/assets/App Store.svg" alt="App Store Button" />
                </a>
            </div>
            <div className="mt-16 px-12 text-center sm:text-3x md:text-4xl">
                Unfortunately Igloo is currently only available as a mobile app.<br />We're working hard to get a website as soon as possible.
            </div>
            <div className="lg:mt-16 mt-8 px-12 text-center sm:text-3xl md:text-4xl">
                To view the Page, download the Igloo mobile app from the Play Store or App Store.
            </div>
            <div className="flex p-4 mt-5 flex-col justify-center" onClick={() => window.location.href = "/#features"}>
                <div className="text-3xl md:text-4xl text-center">
                    Why use Igloo?
                    </div>
                <div className="text-center p-5 rounded-md mt-4 mx-4 md:mx-52 text-purple-600 border-grey shadow-sm hover:shadow-md hover:bg-gray-100 cursor-pointer bg-white">
                    <div className="text-xl md:text-2xl pt-2">
                        Check out all the reasons to shift to Igloo
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Page;